@import 'includes.scss';

.sidenav {
    background: #ffffff;
    width: 250px;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0 0 0;
    position: sticky;
    top: 60px;
    z-index: 99;
    border-right: 2px solid #F3F5FA;
    @media screen and (max-width:767px) {
        width: 0px;
        nav{
             ul li a{ 
                padding: 0 0 0 10px;
                label {display: none;}
            }
             .dropdown {display: none;}
            }
    }
        .navfooter {
        padding: 10px 20px;
        position: sticky;
        bottom: 0;
        background: #fff;
        @media screen and (max-width:767px) {display: none;}
        .upgrade_btn{
            background: #F6FFE3;
            border: 1px solid #8ABB2A4D;
            border-radius: 20px;
            width: 210px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-family: $primaryfontRegular;
            color: #453A2D;
            @media screen and (max-width:767px) {display: none;}
            span{
                cursor: pointer;
                color: #ED5E12;
                font-family: $primaryfontSemiBold;
                padding: 0 5px;
                &:hover{text-decoration: underline;}
            }
        }
    }
}
    nav {
        .dropdown{
            cursor: pointer;
            display: inline-flex;
            position: relative;
            vertical-align: middle;
            color: $white;
            font-size: 14px;
            font-family: $primaryfontSemiBold;
            background: #21988C;
            border-radius: 6px;
            border: 0;
            height: 40px;
            width: 210px;
            margin: 0 20px;
            align-items: center;
            justify-content: center;
            padding: 0 40px 0 0px;
            svg{
                position: absolute;
                right: 0;
                height: 40px;
                width: 40px;                    
                background: #00000033;
                border: 0;
                border-radius: 0px 6px 6px 0px;
                padding: 5px 0 5px;
                display: block;
            }
        }
        .css-advny6-MuiPopper-root {
            z-index: 1;
            .css-1ps6pg7-MuiPaper-root{
                display: flex;
                background: #21988C;
                border-radius: 6px;
                z-index: 1;
                width: 210px;
                flex-direction: column;
                align-items: center;
                a{
                width: 200px;
                color: #FFFFFF;
                text-decoration: none;
                margin: 5px;
                height: 40px;
                font-family: $primaryfontSemiBold;
                font-size: 14px;
                    &:hover{
                    background: #1B7A70;
                    border-radius: 6px;
                    }
                    img{
                        margin: 0 10px;                            
                    }
                }
                ul{
                    margin: 5px;
                    li{
                        color: #FFFFFF;
                        font-size: 14px;
                        font-family: $primaryfontSemiBold;
                        width: 200px;
                        height: 40px;
                        &:hover{
                        background: #1B7A70;
                        border-radius: 6px;
                        }
                        img{
                            padding: 0 10px 0 0;
                        }
                    }
                }
            }
        }
            
        ul {
            padding: 0px;
            margin: 5px 0px 30px;
            li {
                list-style-type: none;
                position: relative;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    height: 50px;
                    width: 100%;
                    padding: 0 0 0 20px;
                    text-decoration: none;
                    cursor: pointer;
                    color: rgba($primary_color , 0.7);
                    font-size: 14px;  
                    font-family: $primaryfontRegular;
                    &.active{
                        color: #8abb2a;
                        background: #E7F5F4;
                        font-weight: $primaryfontMedium;
                    }
                    span {
                        display: block;
                        width: 20px;
                        height: 25px;
                        background-position: center;
                    }
                    label {
                        display:flex;
                        align-items: center;
                        padding: 0 15px;
                        position: static;    
                        cursor: pointer;
                    }
                }
                &.dashboard a{
                    span {
                        background: url('../../images/home.svg') no-repeat top 2px center;
                        &:hover span{
                            background: url('../../images/home-active.svg') no-repeat top 2px center;
                        }
                    } 
                    &.active{
                        span{
                            background: url('../../images/home-active.svg') no-repeat top 2px center;
                        }
                    }
                }
                &.documents a{
                    span {
                        background: url('../../images/documents.svg') no-repeat top 2px center;
                        &:hover span {
                            background: url('../../images/documents_active.svg') no-repeat top 2px center;
                        }
                    }
                    &.active{
                        span{
                            background: url('../../images/documents_active.svg') no-repeat top 2px center;
                        }
                    }
                }
                &.pending a{
                     span {
                        background: url('../../images/pending.svg') no-repeat top 2px center;
                        &:hover span {
                            background: url('../../images/pending-active.svg') no-repeat top 2px center;
                        }
                    }
                    &.active{
                        span{
                            background: url('../../images/pending-active.svg') no-repeat top 2px center;
                        }
                    }
                }
                &.others a{
                    span {
                        background: url('../../images/pending-others.svg') no-repeat top 2px center;
                        &:hover span {
                            background: url('../../images/pending-others-active.svg') no-repeat top 2px center;
                        }
                    }
                    &.active{
                        span{
                            background: url('../../images/pending-others-active.svg') no-repeat top 2px center;
                        }
                    }
                }
                &.completed a{
                     span {
                        background: url('../../images/completed.svg') no-repeat top 2px center;
                        &:hover span {
                            background: url('../../images/completed-active.svg') no-repeat top 2px center;
                        }
                    }
                    &.active{
                        span{
                            background: url('../../images/completed-active.svg') no-repeat top 2px center;
                        }
                    }
                }
                &.drafts a{
                     span {
                        background: url('../../images/drafts.svg') no-repeat top 2px center;
                        &:hover span {
                            background: url('../../images/drafts-active.svg') no-repeat top 2px center;
                        }
                    }
                    &.active{
                        span{
                            background: url('../../images/drafts-active.svg') no-repeat top 2px center;
                        }
                    }
                }
                &.templates a{
                     span {
                        background: url('../../images/templates.svg') no-repeat top 2px center;
                        &:hover span {
                            background: url('../../images/templates-active.svg') no-repeat top 2px center;
                        }
                    }
                    &.active{
                        span{
                            background: url('../../images/templates-active.svg') no-repeat top 2px center;
                        }
                    }
                }
                &.payments a{
                     span {
                        background: url('../../images/payments.svg') no-repeat top 2px center;
                        &:hover span {
                            background: url('../../images/payments-active.svg') no-repeat top 2px center;
                        }
                    }
                    &.active{
                        span{
                            background: url('../../images/payments-active.svg') no-repeat top 2px center;
                        }
                    }
                }
                &.reports a{
                     span {
                        background: url('../../images/reports.svg') no-repeat top 2px center;
                        &:hover span {
                            background: url('../../images/reports-active.svg') no-repeat top 2px center;
                        }
                    }
                    &.active{
                        span{
                            background: url('../../images/reports-active.svg') no-repeat top 2px center;
                        }
                    }
                }
                &.settings a{
                     span {
                        background: url('../../images/settings.svg') no-repeat top 2px center;
                        &:hover span {
                            background: url('../../images/settings-active.svg') no-repeat top 2px center;
                        }
                    }
                    &.active{
                        span{
                            background: url('../../images/settings-active.svg') no-repeat top 2px center;
                        }
                    }
                }
                &:hover {
                    a label {
                        opacity: 1;
                        visibility: visible;
                        left: 100%;
                        z-index: 9;
                        color: #8ABB2A;
                    }
                    &.dashboard span{
                        background: url('../../images/home-active.svg') no-repeat top 2px center;
                    }
                    &.documents span {
                        background: url('../../images/documents_active.svg') no-repeat top 2px center;
                    }
                    &.pending span {
                        background: url('../../images/pending-active.svg') no-repeat top 2px center;
                    }
                    &.others span {
                        background: url('../../images/pending-others-active.svg') no-repeat top 2px center;
                    } 
                    &.completed span {
                        background: url('../../images/completed-active.svg') no-repeat top 2px center;
                    } 
                    &.drafts span {
                        background: url('../../images/drafts-active.svg') no-repeat top 2px center;
                    } 
                    &.templates span {
                        background: url('../../images/templates-active.svg') no-repeat top 2px center;
                    }
                    &.payments span {
                        background: url('../../images/payments-active.svg') no-repeat top 2px center;
                    }
                    &.reports span {
                        background: url('../../images/reports-active.svg') no-repeat top 2px center;
                    }
                    &.settings span {
                        background: url('../../images/settings-active.svg') no-repeat top 2px center;
                    }
                }
            }
        }
    }

nav{
    @media screen and (max-width:767px) {    width: 85% !important; 
        .mobile-nav{
            nav{width: 100% !important;}
        }
    }
}
.mobile-nav{
    display: none;
    @media screen and (max-width:767px) {
        display: block;
        top: 0px;
        position: relative;
        margin: 10px 0;
    }
    
    .close_btn{
        right: 0;
        position: absolute;
        margin: 10px;   
        border: 0;
        background: transparent;         
    }
    .navfooter {
        padding: 10px 20px;
        position: sticky;
        bottom: 0;
        background: #fff;
        .upgrade_btn{
            background: #F6FFE3;
            border: 1px solid #8ABB2A4D;
            border-radius: 20px;
            width: 210px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-family: $primaryfontRegular;
            span{
                color: #ED5E12;
                font-family: $primaryfontSemiBold;
                padding: 0 5px;
            }
        }
    }
    .sidenav-dropdown{
        @media screen and (max-width:767px) {
        display: flex;
        align-items: center;
        }
    }
}