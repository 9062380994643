
.MuiDialog-root {
    .MuiDialog-container {
        > div {width: 100%;}
        .dialog-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 70px;
            background: #F3F5FA;
            padding: 0 25px;
            .MuiDialogTitle-root {
                padding: 0px;
            }
            .dialog-header-rt {
                display: flex;
            }
        }
        .dialog-content {
            padding: 20px 25px;
        }
    }
}