@import 'includes.scss'; 
body {
    background: #F3F5FA;
    .header {
         
    }
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.wrapper {
    display: block; 
    .bodylayout {
        width: 100%;
        background: #F3F5FA;
        display: flex;
        align-items: flex-start;
        .maincontent {
            width: calc(100% - 250px);
            padding:0px;
            .body-content {
                padding:0 20px 20px 20px;
                min-height: calc(100vh - 200px);
                width: 100%;
                @media screen and (max-width:767px) {    padding: 0;}
            }
            @media screen and (max-width:767px) {    width: calc(100% - 5px);padding: 10px;}
        }
    }
}

// .eq-table {
//     padding:20px
// }






///////////////// components /////////////////////////

.primary_btn{
    background:$secondary-color;
    color: $white;
    border-radius: 6px;
    height: 40px;
    line-height: 40px;
    border:none;
    font-family: $primaryfontMedium;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    white-space: nowrap;
    cursor: pointer;
    text-decoration: none;
    margin: 0 5px;
    &.login{
        width: 100%;
        font-family: $primaryfontSemiBold;
        &:hover{
            background: $primary_color;
        }
    }
    &.document{
        background: rgba(138, 187, 42, 0.9);
        transition: all 0s ease;
        &:hover{background: #0d988c;
            transition: all 0.3s ease-in-out !important;
        }
    }

    &.free{
        font-family:  $secondaryfontBold;
        font-size: 13px;
        background: $primary_color;;
        color: $white;
        width: auto;
        &.active{
            color: $primary_color;
            background: transparent;
            border: 1px solid $primary_color;
            width: 100%;
            justify-content: space-around;
        }
    }
    &.contact{
        background: $primary-color;
        font-family: $secondaryfontBold;
        font-size: 13px;
    }
    &.more{
        background: $white;
        text-transform: capitalize;
        color: $black;
        font-size: 12px;
        &:hover{
            color: $white;
        }
    }
    &.csv{
         
        &:hover{
            
            border: 1px solid $primary-color;
            background: $white;
        }
    }
    &.import{
        font-family: $secondaryfontBold;
        font-size: 13px;            
    }
    &.save{
        font-family: $secondaryfontBold;
    }
    &.update_btn{
        width: 130px;
        margin-top: 10px;
    }
    &.document_btn{
        font-family: $primaryfontSemiBold;
    }
    &.profile_btn{
        color: $primary_color;
        background: transparent;
        border: 1px solid $primary_color;
        font-family: $primaryfontMedium;
        font-size: 13px;
        width: 170px;
        &:hover{
            color: $white;
            background: $primary_color;
        }
    }
    &.browse_btn {
        font-family: $secondaryfontBold;
        width: 107px;
        height: 40px;
    }
    &.signer_btn{
        font-family: $secondaryfontBold;
        width: 110px;
    }
    &:hover{
        background: $primary-color;
    }
    
    @media screen and (max-width:991px) {
            font-size: 12px;
            &.import {
                font-size: 12px;
            }
    }
    @media screen and (max-width:767px) {
        font-size: 12px;
        &.active {
            width: 150px;
            justify-content: space-around;
            &.free{width: 150px;}
        }
        &.profile_btn{
            width: 100%;
            margin-top: 15px;
        }
    }
}

.filter_btn{
    background: $white;
    border: 1px solid #1A18184D;
    border-radius: 6px;
    height: 40px;
    font-family: $secondaryfontMedium;
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    white-space: nowrap;
    color: #1A1818;
    &:after {
        content: '';
        background: url('../../images/download.svg');
        background-repeat: no-repeat;
        border: 0;
        width: 12px;
        height: 12px;
        right: 10px;
        position: absolute;
    }
    img{
        width: 12px;
    }
    span{
        margin: 0 20px 0 10px;
    }
}

.status {
    font-family: $primaryfontMedium;
    font-size: 14px;
    position: relative;
    padding-left: 20px;
    white-space: nowrap;
    &:before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border: 3px solid #FFFFFF86;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
    &.pending {
        color: #DE6417;
        &:before {
            background: #DE6417;
        }
    }
    &.completed {
        color: #71A014;
        &:before {
            background: #71A014;
        }
    }
    &.cancelled {
        color: #1A1818;
        &:before {
            background: #1A1818;
        }
    }
    
    @media screen and (max-width:991px) {
            font-size: 12px;
            padding-left: 15px;
            &:before {
                content: '';
                width: 10px;
                height: 10px;
            }
    }
    @media screen and (max-width:767px) {
        font-size: 12px;
    }
}

.secondary_btn {
    background:$white;
    color: #0D988C;
    border: 1px solid #0D988C;
    border-radius: 6px;
    height: 40px;
    line-height: 40px;
    font-family: $secondaryfontMedium;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    white-space: nowrap;
    min-width: 100px;
    cursor: pointer;
    &:hover{
        background:#0D988C;
        color: $white;
        border: 1px solid $white;
    }
}

.btn_light{
    background:transparent;
    color: $primary-color;
    border: 1px solid transparent;
    border-radius: 6px;
    height: 40px;
    line-height: 40px;
    font-family: $secondaryfontMedium;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
    white-space: nowrap;
    cursor: pointer;
    &:hover{
        background:#F3F5FA;
        color: $primary-color;
        border: 1px solid #F3F5FA;
    }
}

/////////// Page Sub-header Styles ///////////////// 
.sub-header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 20px;
    .page-title{
        h3{
            font-family: $primaryfontMedium;
            font-size: 18px; 
            padding: 0px;
            margin: 0;
            font-weight: normal;
        }
    }
    .page-filters {
       
        ul{
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            list-style-type: none;
            align-items: center;
            background: transparent;
            min-height: auto;
          li{
                margin:0 0 0 10px;
                padding: 0;
                &:hover{background: none;}
                .css-advny6-MuiPopper-root {
                    z-index: 9;
                    .css-1ps6pg7-MuiPaper-root{
                        width: 180px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        ul{
                            li{
                                color: #1A1818;
                                font-family: $primaryfontRegular;
                                font-size: 14px;
                            }
                        }
                    }
                }
                .dropdown{
                    width: 180px;
                    border: 1px solid #1A18181A;
                    background: #fff;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: space-around; 
                    position: relative;
                    border-radius: 6px;
                    color: #1A1818;
                    font-family: $primaryfontRegular;
                    font-size: 14px;
                    .dropdown-menu{
                        display: none;
                        &.show{
                            display: block;
                            width: 100%;
                            li {
                                padding: 5px;
                            }
                        }
                    }
                }
                .search_area{
                    height: 40px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    .form-control {
                        background: url('../../images/search.png') no-repeat $white;
                        background-size: 12px;
                        background-position: 12px center ;
                        width: 280px;
                        height: 40px;
                        border: 1px solid rgba($primary-color , 0.1);
                        border-radius: 6px;
                        font-family: $primaryfontRegular;
                        font-size: 14px;
                        color: $primary-color;
                        padding-left: 35px;
                        padding-right: 10px;
                        &:focus {
                            box-shadow: none;
                        }
                        &::placeholder {
                            color: rgba($primary-color , 0.5);
                        }
                    } 
                }
                .MuiSelect-select{    min-width: 180px;    border: 1px solid rgba(26, 24, 24, 0.1) !important;}
            }
        }
    }
    &.v2 {
        background: $white;
    }
    @media screen and (max-width:952px) {
        height: auto;
        padding-bottom: 10px;
        .page-filters ul {
            min-height: auto;
            z-index: 0;
            li {margin: 5px 0px 0 5px}
        }
    }
    @media screen and (max-width:767px) {
        display: block;
        height: auto;
        padding: 0;
        margin: 10px 0;
        .page-filters ul {min-height: auto;
            display: block;
            li{
                margin: 5px 5px 5px 0;
                .search_area .form-control {
                    width: 100%;
                }
            }
        }
        &.v2 {
            padding: 10px;
            .page-filters ul {
                display: flex;
                li{
                    .secondary_btn {
                        min-width: auto;
                    }
                }
            }

        }
    }
}

.list-elements {
    ul {
        display: flex;
        align-items: center;
        li {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            list-style-type: none;
            figure {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 50%;
                overflow: hidden;
                img {
                    min-width: 100%;
                    height: auto;
                    max-height: 100%;
                    display: block;
                }
                &:hover {
                    box-shadow: 0px 0px 20px #00000033;
                }
            }
            label {
                width: 100%;
                height: 100%; 
                background: $white;
                border: 1px solid $primary-color;
                border-radius: 50%;
                overflow: hidden;
                font-family: $primaryfontMedium;
                font-size: 14px;
                color: #655C52;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                &:hover {
                    box-shadow: 0px 0px 20px rgba($black , 0.2); 
                }
            }
            &.more-elements {
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    background: $base-color;
                    border-radius: 50%;
                    font-family: $primaryfontMedium;
                    font-size: 14px;
                }
            }
            &.completed {
                position: relative;
                &:after {
                    content: '';
                    background: url('../../images/checked-green.svg') no-repeat $white;
                    display: block;
                    width: 12px;
                    height: 12px;
                    position: absolute;
                    bottom: -2px;
                    right: -2px;
                    border: 2px solid $white;
                    border-radius: 50%;
                    background-position: center;
                }
                figure {
                    border: 1px solid #8ABB2A;
                }
                label {
                    border: 1px solid #8ABB2A;
                }
            }
            &.inprogress {
                position: relative; 
                figure {
                    border: 1px solid #1A181880;
                }
                label {
                    border: 1px solid #1A181880;
                }
            }
        }
    }
}

.MuiTooltip-popper {
    .MuiTooltip-tooltip {
        background: $white;
        box-shadow: 0px 0px 20px rgba($black , 0.16); 
        border-radius: 8px;
        ul {
            padding: 5px 0;
            li {
                display: flex;
                align-items: center; 
                list-style-type: none;
                padding: 5px 10px;
                min-width: 150px;
                border-radius: 5px;
                figure {
                    width: 28px;
                    height: 28px;
                    display: block;
                    border-radius: 50%;
                    overflow: hidden;
                    img {
                        min-width: 100%;
                        height: auto;
                        max-height: 100%;
                        display: block;
                    }
                }
                span {
                    width: 28px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    overflow: hidden;
                    background: $base-color;
                    font-family: $primaryfontSemiBold;
                    font-size: 10px;
                    color: $primary-color;
                    text-transform: uppercase;
                }
                label {
                    font-family: $primaryfontMedium;
                    font-size: 12px;
                    color: $primary-color;
                    padding: 0px 0 0 10px;
                }
                &:hover {
                    background: #fafafa;
                }
            }
        }
        .MuiTooltip-arrow  {
            &:before {
                background-color: $white;
            }
        }    
        .usericon-details {
            display: block;
            text-align: center;
            padding: 8px 0;
            label {
                font-family: $primaryfontMedium;
                font-size: 14px;
                line-height: 17px;
                color: $primary-color;
                padding: 0px 0 3px 0px;
                display: block;
            }
            p {
                font-family: $primaryfontRegular;
                font-size: 12px;
                color: $primary-color;
                padding: 0px 0 0 0px;
            }
        }    
        .actions-menu {
            ul {
                li {display: block;
                    text-align: right;
                    min-width: 100px;
                    a {
                        display: block;
                        width: 100%;
                        font-family: $primaryfontMedium;
                        font-size: 14px;
                        color: $primary-color;
                        padding: 3px 3px 0 10px;
                        text-decoration: none;
                    }
                }
            }
        }
    }   
    &.MuiPopperUnstyled-root[data-popper-placement*="bottom"] .MuiTooltip-tooltip { 
        margin-top: 8px;
    } 
    &.MuiPopperUnstyled-root[data-popper-placement*="top"] .MuiTooltip-tooltip {    
        margin-bottom: 8px;
    }
    &#actions-menu {
        margin-right:-15px !important;
        .MuiTooltip-tooltip .MuiTooltip-arrow {
            margin-left: -14px;
        }
    }
}
 
.user-icon {
    figure {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 50%;
        overflow: hidden;
        img {
            min-width: 100%;
            height: auto;
            max-height: 100%;
            display: block;
        } 
    }
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: #1B7A70;
        border-radius: 50%;
        font-family: $primaryfontMedium;
        font-size: 14px;
        color: $white;
    }
}

/////////// Starts////////////////////

.es-status {
    display: inline-block;
    position: relative;
    font-family: $primaryfontMedium;
    font-size: 13px;
    color: $primary-color;
    padding-left: 20px;
    &:before {
        display: block;
        content: '';
        position: absolute;
        left: 0px;
        top: 3px;
        width: 5px;
        height: 5px;       
        border-radius: 50%;
    }
    &.In-Progress {
        color:#DE6417;
        &:before {
            border: 4px solid rgba($white , 0.5);
            background: #DE6417;
        }
    }
    &.Completed {
        color:#8ABB2A;
        &:before {
            border: 4px solid rgba($white , 0.5);
            background: #8ABB2A;
        }
    }
    &.Cancelled {
        color:#858588;
        &:before {
            border: 4px solid rgba($white , 0.5);
            background: #858588;
        }
    }
}

////////////////////////////
 


.pad20 {padding: 20px;}
.padt20 {padding-top: 20px;}
.mb-0 {margin-bottom: 0px !important;}
.d-inline-flex {display: inline-flex !important;}
.align-center {align-items: center !important;}

.MuiDropzoneArea-root{
    background: rgb(231 245 244 / 60%);
    border: 1px dashed #1A1818 !important;
    border-radius: 6px !important;
    width: 100% !important;
    max-height: 90px;
    min-height: auto !important;
    box-shadow: none; 
    display: inline-flex;
    align-items: center;
    justify-content: center;
    //margin-top: 5px;
    overflow: initial !important;
    &.customdropzone{
        border: 1px dashed #1A181833;
        background-color: #FAFAFA;
        max-height: 100px;
        min-height: auto;
        .MuiDropzoneArea-textContainer{
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            min-height: 100px;
            p{
                font-family: $primaryfontRegular;
                font-size: 14px;
                color: $primary-color;
                margin: 0;
                &:after {
                    content: "Browse";
                    color: #8ABB2A;
                    opacity: 1;
                    transform: translateX(-100%);
                    position: absolute;
                    text-decoration: underline;
                }
            }
            .MuiDropzoneArea-icon{
                width: 42px;
                height: 40px;
                margin-right: 10px;
                background: url('../../images/cloud-computing-new.svg') no-repeat;
                path{
                    display: none;
                }
            }
        }
    }
    p{
        color: #1A1818;
        font-size: 14px;
        font-family: $primaryfontMedium;
    }
    .MuiDropzoneArea-icon{
        display: none;
    }
    .MuiChip-deleteIcon {
        position: absolute;
        top: -10px;
        z-index: 102;
        right:0;
        background: #333;
        color: #fff;
        opacity: 1;
        border-radius: 50%;
        margin: 0;
    }
    .MuiDropzoneArea-text{
        color: rgb(51, 51, 51, 0.7);
        font-family: $primaryfontRegular;
        font-size: 14px;
    }
    .MuiDropzonePreviewList-removeButton{    right: 0;
        top: -15px;}
        .MuiDropzonePreviewList-imageContainer{
            position: initial;
            .MuiDropzonePreviewList-image {
                min-height: 70px;
                height: 70px;
            }
            .MuiChip-outlined{position: initial;
                .MuiChip-label {    width: 130px;}
                @media screen and (max-width:1200px) {    width: 90px;}
            }
        }
}
.saved-template{
    .MuiDropzoneArea-root.customdropzone .MuiDropzoneArea-textContainer p{
        &:after {
            content: "Saved Template";
            color: #fff;
            opacity: 1;
            transform: translateX(-100%);
            position: absolute;
            text-decoration: none;
            background: #8ABB2A;
            border-radius: 6px;
            line-height: 30px;
            width: 45%;
            white-space: nowrap;
            margin: -5px 9px;
            max-width: 115px;
        }
    }
}
.es-accordian{
    .MuiAccordion-root{
        border-radius: 6px !important;
        box-shadow: none;
        margin-bottom: 10px;
        &::before{
            display: none;
        }
        > .MuiButtonBase-root{
            min-height: auto;
            padding: 10px 15px;
            .MuiAccordionSummary-content{
                margin: 0;
                .header-actions{
                    display: flex;
                    align-items: center;
                    .form-check{
                        margin-left: 15px;
                    }
                }
                p{
                    font-family: $primaryfontMedium;
                    font-size: 14px;
                    color: $primary-color;
                    font-weight: normal;
                    margin: 0;
                }
            }
        }
        &.Mui-expanded{
            margin: 10px 0;
            .MuiAccordionSummary-root{
                border-bottom: 1px solid rgba($color: $primary-color, $alpha: 0.1);
            }
        }
        .MuiCollapse-root{
            .MuiAccordionDetails-root{
                padding: 15px;
                .form-group:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    .recipient-actions{
        display: flex;
        .form-check:first-child{
            margin-right: 20px;
        }
    }
    .add-signer-sec{
        > ul{ 
           > li{
                display: flex;
                align-items: center;
                background: #FAFAFA ;
                padding: 10px 10px 10px 20px;
                border-radius: 6px;
                margin-top: 10px;
                margin-right: 35px;
                position: relative;
                &:last-child{
                    margin-bottom: 10px;
                }
                .form-group{
                    margin: 0;
                    .sign-select{
                        min-width: 200px;
                        margin-left: 10px;
                    }
                    .MuiSelect-select{
                        min-width: 200px;
                        margin-left: 10px;
                    }
                }
                .order{
                    min-width: 9px;
                    height: 15px;
                    background: url('../../images/order.svg') no-repeat center center;
                    margin-right: 20px;
                    cursor: all-scroll;
                }
                .delete-row{
                    min-width: 15px;
                    height: 16px;
                    background: url('../../images/delete.svg') no-repeat center center;
                    cursor: pointer;
                    position: absolute;
                    right: -30px;
                }
                .dropdown-button{
                    .dropdown{
                        @include inputborder;
                        border-radius: 6px;
                        background: $white;
                        height: 40px;
                        width: 200px;
                        display: inline-flex;
                        position: relative;
                        vertical-align: middle;
                        margin-left: 10px;
                        align-items: center;
                        justify-content: space-between;
                        font-family: $primaryfontRegular;
                        font-size: 14px;
                        color: rgba($color: $primary-color, $alpha: 0.7);
                        padding: 0 15px;
                        line-height: 38px;
                        svg{
                            width: 12px;
                            height: 7px;
                            background: url('../../images/down-chevron.svg') no-repeat;
                            path{
                                display: none;
                            }
                        }
                    }
                    .MuiPopperUnstyled-root .MuiList-root{
                        background: $white;
                        box-shadow: 0px 0px 15px #0000001A;
                        border-radius: 6px;
                        width: 200px;
                        min-height: 74px;
                        padding: 12px 15px;
                        li.MuiMenuItem-root{
                            font-family: $primaryfontRegular;
                            font-size: 14px;
                            color: rgba($color: $primary-color, $alpha: 0.8);
                            padding: 0;
                            &:not(:last-child){
                                margin-bottom: 10px;
                            }
                            &:hover{
                                background: transparent;
                                color: $primary-color;
                            }
                        }
                    }
                }
            }
        }
        .add-signer-link{
            font-family: $primaryfontRegular;
            font-size: 14px;
            color: #0D988C;
            padding-left: 20px;
            background: url('../../images/add-user.svg') no-repeat left center;
            margin-top: 15px;
            text-decoration: none;
        }
        @media screen and (max-width:991px) {
            ul li{
                display: block;
                position: relative;
                .order{
                    position: absolute;
                }
                .form-group{
                    &:last-child {
                    margin-bottom: 10px !important;
                    margin-top: 25px;
                    }
                    .MuiSelect-select {
                        min-width: auto;
                        margin-left: 0px;
                    }
                }
                .delete-row {
                    bottom: 0;
                }
            }
        }
    }
}

.form-check{
    .MuiFormControlLabel-root{
        display: flex;
        align-items: center;
        margin: 0;
        span.MuiButtonBase-root{
            padding: 1px 5px 0 0px;
            &:hover{
                background: transparent;
            }
            svg{
                border: 1px solid #CBCBCB;
                border-radius: 2px;
                width: 16px;
                height: 16px;
                path{
                    display: none;
                }
            }
            &.Mui-checked{
                svg{
                    border: 1px solid $secondary-color;
                    border-radius: 2px;
                    width: 16px;
                    height: 16px;
                    background: $secondary-color url('../../images/checked-white.svg') no-repeat center center;
                    background-size: 10px;
                }
            }
        }
        span.MuiTypography-root{
            font-family: $primaryfontRegular;
            font-size: 14px;
            color: $primary-color;
        }
    }
}

.select-dropdown {
    background: $white;
    height: 40px;
    min-width: 200px;
}

#menu-select{z-index: 1;}

.radio_btn{
    margin-bottom: 10px;
    .MuiIconButton-label{
        input:checked ~ div svg{
            fill: #8ABB2A;
        }
    }
}

.file_upload_wrapper{
    position: relative;
    .loader{
        position: absolute; 
        left:0px; 
        top:0px; 
        width:100%; 
        height:100%; 
        display:flex;  
        align-items: center; 
        justify-content: center; 
        background:rgba(#D8D8D8 , 0.7); 
        border-radius:5px; 
        z-index:9;
        img {width:30px; height: 30px;}
    }
}


.no_data {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.password_area{
    position: relative;
    margin-bottom: 0;
    img{
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
    .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input{
        padding-left: 40px;
    }
}