// $InterBold: 'InterBold';
// $InterSemiBold: 'InterSemiBold';
// //$LatoItalic: 'LatoItalic';
// $InterMedium: 'InterMedium';
// $InterRegular: 'InterRegular';
// $InterLight: 'InterLight';
// $DejaRipBold: 'DejaRipBold';
// $DejaRipMedium: 'DejaRipMedium';

//////////////////////////////////////////
$primaryfontBold: 'InterBold';
$primaryfontSemiBold: 'InterSemiBold'; 
$primaryfontMedium: 'InterMedium';
$primaryfontRegular: 'InterRegular';
$primaryfontLight: 'InterLight';

$secondaryfontBold: 'DejaRipBold';
$secondaryfontMedium: 'DejaRipMedium';


@font-face {
    font-family: 'InterBold';
    src: url("../../../public/fonts/Inter-Bold.woff") format('woff'),
         url("../../../public/fonts/Inter-Bold.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'InterSemiBold';
    src: url("../../../public/fonts/Inter-SemiBold.woff") format('woff'),
         url("../../../public/fonts/Inter-SemiBold.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}
// @font-face {
//     font-family: 'LatoItalic';
//     src: url("../../../public/fonts/Lato-Italic.woff") format('woff'),
//          url("../../../public/fonts/Lato-Italic.woff2") format('woff2');
//     font-weight: normal;
//     font-style: normal;
// }
@font-face {
    font-family: 'InterMedium';
    src: url("../../../public/fonts/Inter-Medium.woff") format('woff'),
         url("../../../public/fonts/Inter-Medium.woff2") format('woff2');
    font-weight: medium;
    font-style: medium;
}
@font-face {
    font-family: 'InterRegular';
    src: url("../../../public/fonts/Inter-Regular.woff") format('woff'),
         url("../../../public/fonts/Inter-Regular.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'InterLight';
    src: url("../../../public/fonts/Inter-Light.woff") format('woff'),
         url("../../../public/fonts/Inter-Light.woff2") format('woff2');
    font-weight: light;
    font-style: light;
}
@font-face {
    font-family: 'DejaRipBold';
    src: url("../../../public/fonts/DejaRip-Bold.woff") format('woff'),
         url("../../../public/fonts/DejaRip-Bold.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'DejaRipMedium';
    src: url("../../../public/fonts/DejaRip-Medium.woff") format('woff'),
         url("../../../public/fonts/DejaRip-Medium.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

