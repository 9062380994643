@import 'includes.scss';

.header {
    background: transparent;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    background: $white;
    border-bottom: 2px solid #F3F5FA;
    position: sticky;
    top: 0px;
    z-index: 999;
    svg{display: none;}
    // .header_left{
    //     .page_title{
    //         font-family: $InterMedium;
    //         font-size: 24px; 
    //         padding: 0px;
    //         margin: 0;
    //     }
    // }
    #logo {
        a {
            display: block;
            img {
                display: block;
                height: 35px;
            }
        }
    }
    .header_right {
        ul{
            display: flex;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;
            list-style-type: none;
            align-items: center;
          li{
                margin:0px 0px 0 20px;
                span{
                    background: #F3F5FA 0% 0% no-repeat padding-box;
                    border-radius: 23px;
                    width: 45px;
                    height: 45px;
                    font-size: 14px;
                    font-family: $primaryfontSemiBold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .dropdown{
                    .dropdown-menu{
                        display: none;
                        &.show{
                            display: block;
                            width: 100%;
                            li {
                                padding: 5px;
                            }
                        }
                    }
                }
                .search_area {
                    height: 40px;
                    // @include inputborder ; 
                }
            }
        }
        @media screen and (max-width:767px) {
            ul li {
                margin: 0px 0px 0 10px;
            }
        }
    }
    @media screen and (max-width:767px) {
        #logo{
            display: flex;
            align-items: center;
            a img {
                height: 30px;
            }
            svg{
                background: #333;
                width: 25px;
                height: 25px;
                margin: 0 8px;
                padding: 3px;
                display: block;
            }
        }
    }
}