@import 'includes.scss';  

.custom_modal{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background: rgba(0, 0, 0, 0.5);
    &.open{
        display: block;
    }
    .modal-dialog{
        max-width: 650px;
        margin: 1.75rem auto;
        display: flex;
        align-items: center;
        position: relative;
        width: auto;
        min-height: calc(100% - 3.5rem);
        transition: transform .3s ease-out;
        .modal-content{
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            pointer-events: auto;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0,0,0,.2);
            border-radius: 0.3rem;
            outline: 0;
            .modal-header{
                display: flex;
                flex-shrink: 0;
                align-items: center;
                justify-content: space-between;
                padding: 1rem 1rem;
                border-bottom: 1px solid #dee2e6;
                border-top-left-radius: calc(0.3rem - 1px);
                border-top-right-radius: calc(0.3rem - 1px);
                background: #F3F5FA;
                .modal-title{
                    font-size: 16px;
                    font-family: $primaryfontRegular;
                    color: $primary-color;
                }
                .close_btn{
                    cursor: pointer;
                }
            }
            .modal-body{
                position: relative;
                flex: 1 1 auto;
                padding: 1rem;
                .confirm_msg{
                    font-size: 16px;
                    font-family: $primaryfontRegular;
                    color: $primary-color;
                    text-align: center;
                    min-height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .modal-footer{
                display: flex;
                flex-wrap: wrap;
                flex-shrink: 0;
                align-items: center;
                justify-content: flex-end;
                padding: 0.75rem;
                border-top: 1px solid #dee2e6;
                border-bottom-right-radius: calc(0.3rem - 1px);
                border-bottom-left-radius: calc(0.3rem - 1px);
            }
        }
        &.change_pwd_dialog{
            max-width: 450px;
        }
    }
}