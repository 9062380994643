@import 'includes.scss';  


.eq-table {     
    .MuiPaper-root {
        background: transparent;
        border: 0px;
        box-shadow: none;
    }
    table{
        border-collapse: separate;
        border-spacing: 0px 5px;
        padding: 0px;
        thead{
            background: transparent;
            color: rgba($primary-color , 0.5);
            margin-bottom: 10px;
            tr{
                th{
                    font-family: $primaryfontMedium;
                    font-size: 12px;
                    line-height: 15px;
                    font-weight: 400;
                    white-space: nowrap;
                    border: none;
                    vertical-align: middle;
                    position: relative;
                    padding: 0px 5px;
                    &:nth-child(1){
                        padding-left: 20px; 
                        display: flex;
                        align-items: center;
                    }
                    &:nth-last-child(1){
                        padding-right: 20px; 
                        text-align: right;
                    }   
                    .MuiCheckbox-root {
                        height: 20px;
                    }                  
                }
                td{
                    font-family: $primaryfontMedium;
                    font-size: 13px;
                    color: $primary-color;
                }                
            }
        }
        tbody{
            padding: 0 20px;            
            tr{  
                th {
                    padding: 5px;
                    border: 0px;
                    background:$white;
                    &:nth-child(1){
                        padding-left: 20px; 
                    }
                    &:nth-last-child(1){
                        padding-right: 20px; 
                    } 
                   
                }        
                td{
                    border: none;
                    vertical-align: middle;
                    position: relative;
                    padding: 5px;
                    height: 60px;
                    background:$white;
                    .user-icon{
                        span{
                            color: $white;
                            font-family: $primaryfontMedium;
                            font-size: 12px;
                        }
                    }
                    span{
                        color: #1A1818;
                        font-size: 13px;
                        font-family: $primaryfontRegular;
                    }
                    &:first-child{
                        border-radius: 10px 0 0 10px;
                        padding-left: 20px; 
                        span{
                            color: #1A1818;
                            font-size: 14px;
                            font-family: $primaryfontMedium;
                        }
                    }
                    &:last-child{
                        border-radius: 0 10px 10px 0;
                        padding-right: 20px; 
                        text-align: right;
                        .flex-label {
                            justify-content: end;
                        }
                    }
    
                    .users_list{
                        ul{
                            list-style:none;
                            padding: 0;
                            margin: 0;
                            display: flex;
                            align-items: center;
                            li{
                                margin-right: 10px;
                                position: relative;
                                &:first-child{
                                    padding-left: 70px;
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                                &.you{
                                    margin-right: 30px;
                                    padding-left: 0;
                                    .divider{
                                        position: absolute;
                                        width: 1px;
                                        height: 40px;
                                        background: #1A1818;
                                        opacity: 0.4;
                                        //margin: 0 15px;
                                        top: 0;
                                        right: -15px;
                                    }
                                }
                                .checked{
                                    position: absolute;
                                    bottom: -2px;
                                    right: -2px;
                                    background: $white;
                                    border: 2px solid $white;
                                    border-radius: 50%;
                                    display: flex;
                                    align-items: center;
                                }
                                figure{margin: 0;}
                                .user_info {
                                    width: 40px;
                                    height: 40px;
                                    display: flex;
                                    align-items: center;
                                    border: 1px solid #1A1818;
                                    justify-content: center;
                                    border-radius: 50%;
                                    font-family: $primaryfontMedium;
                                    font-size: 12px;
                                    &.active{
                                        background: $black;
                                        color: #fff;
                                    }
                                    &.success{
                                        border: 1px solid #8ABB2A;
                                    }
                                    &.pending{
                                        border: 2px solid #D6CDC4;
                                    }
                                }
                            }
                        }
                    }
                    .waiting-list{
                        color: #1A18189A;
                        font-size: 12px;
                        font-family: $primaryfontRegular;
                        padding: 0 10px;
                        span{
                            color: #0D988C;
                            text-decoration: underline;
                        }
                    }
                    .files{
                        display: flex;
                        align-items: flex-end;
                        margin-left: 15px;
                        .file_name{
                            margin-left: 15px;
                            line-height: normal;
                            h6{
                                font-family: $secondaryfontBold;
                                font-size: 16px;
                                margin: 0;
                                color: $primary-color;
                            }
                            em{
                                font-family: $secondaryfontMedium;
                                font-size: 12px; 
                            }
                        }
                    }
                    .actions-td{
                        display: inline-block;
                        .actions-icon {
                            width: 25px;
                            height: 25px;
                            background: red;
                            display: block;
                            background: url('../../images/actions-menu.png') no-repeat center;
                            background-size: auto 20px;
                            
                            &:hover {
                                background: url('../../images/actions-menu-hover.png') no-repeat center;
                                background-size: auto 20px;
                            }
                        }
                    }
                    .td-link {
                        font-family: $primaryfontRegular;
                        font-size: 12px;
                        color: #1A18189A;
                        padding-right: 20px;
                        span {
                            font-family: $primaryfontMedium;
                            color:#0D988C;
                            text-decoration: underline;
                            margin-left: 5px;
                            cursor: pointer;
                        }
                    }
                    .primary_btn{
                        height: 30px;
                        font-size: 12px;
                        font-family: $primaryfontSemiBold;
                    }
                }
                &:hover{
                    background: $white;
                    box-shadow: 0px 0px 20px #0000000D;                    
                }
            }
        }

        .MuiCheckbox-root {
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 38px;
            height: 38px;
            color: #CBCBCB !important;
            &.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked{color: #8ABB2A !important;}
        }
    
        @media screen and (max-width:991px) {
            tbody tr td{
                white-space: nowrap;
                img{
                    width: auto;
                }
                 .files{
                    .file_name h6 {
                    font-size: 14px;
                    }
                    img{width: 30px;}
                 } 
            }
        }
        @media screen and (max-width:767px) {
            tbody tr td{
                white-space: nowrap;
                img{width: auto;}
                .files{
                    align-items: center;
                    .file_name{
                         h6 {
                        font-size: 12px;
                        white-space: nowrap;
                        }
                        em {
                            font-size: 10px;
                        }
                    }
                    img{
                        width: 30px;
                    }
                }
                .users_list ul li .user_info {
                    width: 30px;
                    height: 30px;
                    font-size: 10px;
                    img{width: 30px;}
                }
            }
        }
        @media screen and (max-width:600px) {
           tbody tr td{
                img {
                    width: 8px;
                }
                 .files{
                    img {
                    width: 20px;
                    }
                    h6 {
                        font-size: 10px;
                    }
                }
                .table tbody tr td .users_list ul li .user_info {
                    width: 25px;
                    height: 25px;
                    font-size: 10px; 
                    img {
                        width: 25px;
                    }
                }
            }
        }
        &.contact_table{
            margin-bottom: 78px;
                thead{
                    tr{
                        th {
                            &:nth-child(2) {
                                padding-left: 20px;
                            }
                            &:last-child {
                            padding: 0px 20px;
                            }
                        
                        }
                    }
                }
                tbody{
                    border: 0 !important;
                    tr{
                        height: 40px;
                        td{padding: 0 20px;}
                    }
                }
                .form-check{
                    label{
                        letter-spacing: 0px; 
                        
                        font-size: 13px;
                        font-family: secondaryfontBold;
                    }
                    .form-check-input{
                        width: 20px;
                        height: 20px;
                        border: 1px solid #70707066;
                        border-radius: 4px;
                        
                        margin: 0;
                        margin-right: 20px;
                        cursor: pointer;
                        &:checked {
                            background-color: $primary-color;
                        }
                        &:checked[type=checkbox] {background-image: url(../../images/checkbox-white.svg);}
                    }
                    label{
                        color: $black;
                        font-size: 14px;
                        font-family: $primaryfontMedium;
                    }
                }
            tr{
                &:hover{
                    background: $white;
                }
                &.active{
                    background: $white;
                    border-radius: 6px;
                    
                }
                th{
                    padding: 0px 30px;
                    height: 40px;
                    position: relative;
                }
                td{
                    padding: 0px 20px;
                    height: 50px;
                    font-family: $primaryfontRegular;
                    font-size: 14px;letter-spacing: 0px;
                    color: $black;
                    
                    position: relative;
                }
            }
            @media screen and (max-width:767px) {
                .table{
                    label {
                    font-size: 12px;
                    white-space: nowrap;
                    }
                    .form-check{
                        .form-check-input {
                        width: 15px;
                        height: 15px;
                        margin-right: 15px;
                        }
                    }
                    thead{
                        tr{
                            th{
                                &:first-child {
                                padding-left: 5px;
                                }
                                &:nth-child(2) {
                                    padding-left: 5px;
                                }
                                &:last-child {
                                    padding: 0px 5px;
                                }
                            }
                        }
                    }
                    tbody{
                        tr td {
                            font-size: 12px;
                            padding: 0 15px 0 5px;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
        &.b_method_table{
            overflow: auto;
            max-height: 500px;
            thead{
                tr{
                    th{ 
                        padding: 0 10px;
                        font-family: $primaryfontMedium;
                        color: $primary-color;
                        &:first-child {
                            padding-left: 20px;
                        }
                        &:nth-child(2) {
                            padding-left: 10px;
                        }
                    }
                }
            }
            tbody tr {
                height: 80px;
                &:hover{    background: #f3f5fa;box-shadow: none;}
                td{
                    border-bottom: 1px solid rgba(26, 24, 24, 0.05);
                    border-radius: 0;    padding: 0 10px;
                    font-size: 13px;
                    font-family: $primaryfontMedium;
                    color: $primary-color;
                }
            }
            .pdf{
                border: 1px solid $primary-color;
                border-radius: 15px;
                width: 60px;
                letter-spacing: 0px;
                color: $primary-color;
                
                height: 30px;
                font-size: 13px;
                font-family: 'InterMedium';
                background: #fff;
                &:hover{
                    color: $white;
                    background: $primary-color;
                }
            }
            &::-webkit-scrollbar {
                width: 5px;
                opacity: 0.1;
                border-radius: 20px;
            }
            &::-webkit-scrollbar-track {
            background: #F3F5FA; 
            }
            &::-webkit-scrollbar-thumb {
            background: rgba(101, 92, 82, 0.1); 
            }
        }
        @media screen and (max-width:767px) {
            .table-responsive{
                .table{
                     tbody tr td {
                    font-size: 12px;
                    }
                    thead tr th {
                        font-size: 12px;
                        &:first-child {
                            padding-left: 10px;
                        }
                    }
                    .pdf {
                        width: 50px;
                        height: 25px;
                        font-size: 12px;
                    }
                }
            }
        }
        .flex-label{
            display: flex;
            align-items: center;
        }
    }
    &.checktable {
        table {
            thead tr th:nth-child(1){
                padding-left: 10px;
            } 
            tbody tr td:nth-child(1){
                padding-left: 10px;
            }
        }       
    }
}

.MuiPagination-root{
    padding: 0 20px 20px;
    .MuiPagination-ul{
        li button{
            border: 1px solid #1A18181A;
            color: #000;
            min-width: 30px;
            height: 32px;
            &.Mui-selected{
                background: #21988C;
                color: #fff;
                &:hover{
                    background: #21988C;
                    color: #fff;
                }
            }
        }
    }
}


