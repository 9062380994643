@mixin background-gradient {
    background-image: linear-gradient(90deg, #F4F4F4 0px, rgba(229,229,229,0.8) 40px, #F4F4F4 80px);
    background-size: 600px;
}
  
  
  .tg{
      border-collapse:separate;
      border-spacing:0px 5px ;
      border-color:#ccc;
      margin: 0;
      width: 100%;
   }
   .tg tr{
      border: none;
      background: #FFFFFF;
      height: 50px;
      margin-bottom: 5px;
   }
   .tg td{
     font-family:Arial, sans-serif;
     font-size:14px;
     padding:0px 5px;
     border-style:solid;
     border-width:0px;
     overflow:hidden;
     word-break:normal;
     border-color:#ccc;
     color:#333;
     background-color:#fff;
     text-align: center;
     &:first-child{
      border-radius: 6px 0 0 6px;
     }
     &:last-child{
      border-radius: 0px 6px 6px 0;
     }
   }
   .tg th{
     font-family:Arial, sans-serif;
     font-size:14px;
     font-weight:normal;
     padding:0 5px;
     border-style:solid;
     border-width:0px;
     overflow:hidden;
     word-break:normal;
     border-color:#ccc;
     color:#333;
     background-color:rgba($color: #D5D5D9, $alpha: 0.5);
     text-align: center;
     &:first-child{
      border-radius: 6px 0 0 6px;
     }
     &:last-child{
      border-radius: 0px 6px 6px 0;
     }
   }
   
   .line{
       width: 100%;
       max-width: 120px;
       height: 18px;
       margin: 6px auto;
       border-radius: 15px;
       
       @include background-gradient;
       animation: shine-lines 2.0s infinite ease-out;
   }
   
    
   @keyframes shine-lines{
       0% { background-position: -100px;}
       40%, 100% {background-position: 140px;}
   }
  