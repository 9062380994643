@import 'includes.scss';

.dashboard-wrapper{
    padding: 20px;
    width: calc(100% - 280px);
    .pending-list{
        display: flex;
        padding: 0;
        margin: 0;
        .pending-list-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: $white;
            border-radius: 6px;
            width: 33%;
            min-height: 80px;
            padding: 14px 20px;
            margin: 0 10px 10px 0;
            &:last-child{
                margin-right: 0;
            }
            h6{
                font-family: $primaryfontRegular;
                font-size: 14px;
                color: $primary-color;
                margin: 0;
                font-weight: normal;
                .count{
                    font-size: 32px;
                    display: block;
                    font-style: normal;
                }
            }
            span{
                width: 32px;
                height: 32px;
            }
            &.pwm{
                span{
                    background: url('../../images/signature_blue.svg') no-repeat;
                    background-size: 100% 100%;
                }
            }
            &.pwo{
                span{
                    background: url('../../images/timer.svg') no-repeat;
                    background-size: 100% 100%;
                }
            }
            &.cfd{
                span{
                    background: url('../../images/draft.svg') no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
        @media screen and (max-width:1023px) {
            flex-direction: column;
            .pending-list-item{
                width: 100%;
                margin-right: 0;
            }
        }
    }
    .create-new-wrapper{
        background: $white;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 60px 20px 50px;
        span{
            width: 175px;
            height: 160px;
            background: url('../../images/create_firts_doc.svg') no-repeat center center;
            background-size: 100% 100%;
        }
        p{
            font-family: $primaryfontRegular;
            font-size: 14px;
            color: $primary-color;
            margin: 30px 0;
            max-width: 312px;
            text-align: center;
        }
        .sign-btns{
            text-align: center;
            .primary_btn{
                margin-bottom: 10px;
                &:first-child{
                    margin-right: 10px;
                    min-width: 170px;
                }
                &:last-child{
                    min-width: 160px;
                }
            }
            @media screen and (max-width:767px) {
                display: flex;
                .primary_btn{
                    &:first-child {
                        min-width: auto;
                    }
                    &:last-child {
                        min-width: auto;
                    }
                }
            }
        }
    }
    .pendingList-sec{
        background: $white;
        border-radius: 6px;
        width: 100%;
        margin-top: 10px;
        .list-header{
            background: #CCE5E3;
            padding: 12px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 6px 6px 0 0;
            h6{
                font-family: $primaryfontMedium;
                font-size: 14px;
                color: rgba($color: $primary-color, $alpha: 0.8);
                font-weight: normal;
            }
            .view_all_link{
                font-family: $primaryfontSemiBold;
                font-size: 12px;
                color: rgba($color: $primary-color, $alpha: 0.7);
                text-decoration: none;
                &:hover{    color: #21988C;}
            }
        }
        .list-items{
            ul{
                padding: 0 20px;
                li{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 20px 0;
                    border-bottom: 1px solid #1A18181A;
                    &:last-child{
                        border-bottom: none;
                    }
                    h6{
                        font-family: $primaryfontMedium;
                        font-size: 14px;
                        color: $primary-color;
                        font-weight: normal;
                        span{
                            display: block;
                            font-family: $primaryfontRegular;
                            font-size: 12px;
                            color: #1A181880;
                            margin-top: 3px;
                            em{
                                font-style: normal;
                                color: #0D988C;
                            }
                        }
                    }

                    .list-right{
                        display: flex;
                        align-items: center;
                        .due-days{
                            font-family: $primaryfontRegular;
                            font-size: 12px;
                            color: rgba($color: $primary-color, $alpha: 0.8);
                            margin-right: 10px;
                            white-space: nowrap;
                            &.overdue{
                                color: #EA5252;
                            }
                        }
                        .primary_btn{
                            max-width: 80px;
                            max-height: 30px;
                            font-size: 12px;
                        }
                        .actions-icon{
                            width: 5px;
                            height: 20px;
                            background: url('../../images/actions-menu.png') no-repeat center center;
                            background-size: 18px;
                            margin-left: 10px;
                            cursor: pointer;
                            &:hover{
                                background: url('../../images/actions-menu-hover.png') no-repeat center;
                                background-size: auto 20px;
                            }
                        }
                        .waiting_list{
                            label{
                                font-family: $primaryfontRegular;
                                font-size: 12px;
                                color: $white;
                                white-space: nowrap;
                                background: #21988C;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 100px;
                                padding: 8px 10px;
                            }
                        }
                    }
                }
            }
            
            @media screen and (max-width:767px) {
                ul li {
                    flex-direction: column;
                    align-items: flex-start;
                    .list-right {
                        position: relative;
                        width: 100%;
                        .actions-icon {
                            position: absolute;
                            right: 0;
                        }
                        .primary_btn {
                            position: absolute;
                            right: 5px;
                        }
                        .waiting_list{
                            position: absolute;
                            right: 10px;
                        }
                    }
                }
            }
        }
        @media screen and (max-width:1023px) {
            
        }
    }
    @media screen and (max-width:1023px) {
        width: 100%;
    }
    @media screen and (max-width:767px) {
       padding: 0;
    }
}

.right-side-nav{
    background: $white;
    width: 280px;
    min-height: calc(100vh - 60px);
    padding: 20px 0;
    //position: sticky;
    position: fixed;
    top: 60px;
    z-index: 99;
    border-left: 2px solid #F3F5FA;
    right: 0;
    h6{
        font-family: $primaryfontMedium;
        font-size: 14px;
        color: $primary-color;
        font-weight: normal;
        margin-bottom: 20px;
        padding: 0 20px;
    }
    .activity-list{
        display: flex;
        flex-direction: column;
        padding: 0px 20px;
        margin: 0;
        .activity-list-item{
            display: flex;
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
            .avatar{
                min-width: 32px;
                height: 32px;
                background: #F3F5FA;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $primaryfontMedium;
                font-size: 11px;
            }
            .details{
                margin-left: 10px;
                p{
                    font-family: $primaryfontMedium;
                    font-size: 14px;
                    color: $primary-color;
                    span{
                        font-family: $primaryfontRegular;
                    }
                }
                a{
                    font-family: $primaryfontRegular;
                    font-size: 14px;
                    color: #0D988C;
                    &:hover{color: #8abb2a;}
                }
                span.timeine{
                    font-family: $primaryfontRegular;
                    font-size: 12px;
                    color: rgba($color: $primary-color, $alpha: 0.5);
                    display: block;
                }
            }
        }
    }
    .active-scroll{
        div:nth-child(2n+1){
            right: 0px;
            left: 0px;
        } 
    }
    @media screen and (max-width:1023px) {
        display: none;
    }
}