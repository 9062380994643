@import 'variables.scss'; 


//form input border
@mixin inputborder($color:rgba($primary-color , 0.34), $width:1px) {
    border: $width solid $color;
}

//form inputfocus border
@mixin inputfocusborder($color:red, $width:1px) {
    border: $width solid $color;
}


@mixin bordered($color:red, $width:1px) {
    border: $width solid $color;
}