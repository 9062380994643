@import 'includes.scss'; 
 

.login_page_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    background: #F0F4F3;
    .login_area{
        min-height: calc(100vh - 70px);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 1;
        position: relative;
        &:before {
            background: url(../../images/bg-after.svg);
            background-repeat: no-repeat;
            background-position:top left;
            content: '';
            position: absolute;
            width: calc(100% - 250px);
            height: calc(100% - 70px);
            z-index: -1;
            opacity: 0.3;
            left: 0;
            top: 0;
        }
        &:after {
            content: '';
            background-image: url(../../images/bg-before.svg);
            background-repeat: no-repeat;
            background-position: top right;
            width: calc(100% - 250px);
            height: calc(100% - 70px);
            position: absolute;
            z-index: -1;
            margin: 0;
            opacity: 0.3;
            top: 0;
            right: 0;
        }
         .login-form{
            .form-group{
                position: relative;
                img {
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translate(0, -50%);
                    z-index: 1;
                }
                .MuiFormControl-root .MuiOutlinedInput-root .MuiInputBase-input {
                    min-width: 300px;
                    border: 0px;
                    border-radius: 5px;
                    font-family: "InterRegular";
                    font-size: 14px;
                    color: $black;
                    height: 40px;
                    line-height: 40px;
                    background:#fff;
                    padding: 0 0px 0 40px;
                    @media screen and (max-width: 767px) {
                        min-width: auto;
                    }
                }
            }
            .forgot_link{
                margin: 20px 0;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .sign_up{
                text-align: center;
                margin-top: 30px;
                p {
                    color: $primary-color;
                    font-family: $primaryfontMedium;
                    font-size: 13px;
                    span{
                        font-family: $primaryfontBold;
                        font-size: 14px;
                        text-decoration: underline;
                    }
                }
            }
            a {
                color: rgba(0, 0, 0, 0.8);
                font-family: $primaryfontMedium;
                font-size: 13px;
                text-decoration: none;
                line-height: 16px;
                &:hover{
                    color: #21988C;
                }
            }
         }
        h4{
            font-family: $primaryfontLight;
            font-size: 32px;
            color: $primary-color;
            margin: 0 0 30px 0;
            font-weight: 500;
            text-align: center;
        }
        input.form-control{
            min-width: 300px;
            border: 1px solid #1A1718;
            border-radius: 5px;
            margin-top: 30px;
            font-family: $primaryfontMedium;
            font-size: 14px;
            color: $black;
            height: 40px;
            line-height: 40px;
            &:focus{
                box-shadow: 0 0 0 0.25rem rgba(130, 30, 64, 0.1);
            }
        }
        .password_area{
            position: relative;
            img{
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translate(0, -50%);
                cursor: pointer;
            }
        }
        .keep_logged_in{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            .form-check .form-check-label{
                font-family: $primaryfontRegular;
                font-size: 14px;
                color: #2E2E2E;
            }
            .form-check-input{
                width: 18px;
                height: 18px;
                &:checked{
                    background-color: #821E40;
                    border-color: #821E40;
                }
                &:focus{
                    box-shadow: 0 0 0 0.25rem rgba(130, 30, 64,  0.1);
                }
            }
            a{
                color: #821E40;
                font-family: $primaryfontRegular;
                font-size: 14px;
                text-decoration: none;
                border-bottom: 1px solid;
                line-height: 16px;
            }
        }
        .login_btn{
             
            color: $white;
            border-radius: 23px;
            min-width: 300px;
            height: 40px;
            border:none;
            margin-top: 30px;
          //  font-family: $InterSemibold;
            font-size: 16px;
        }
    }
}

.login-layout{
    .wrapper .bodylayout .maincontent {
        width: 100%;
        padding: 0px;
    }
}


.loader {position:fixed; top:0px; left:0px; width:100%; height:100%;     width: 100%;
    height: 100%;
    background: rgba($white , 0.6); border-radius:0 20px 20px 0;
    z-index: 9999;
    display: flex; align-items: center; justify-content: center;
    img {width:50px}
}