@import 'includes.scss';

.side-nav-tabs{
    .react-tabs{
        display: flex;
        align-items: flex-start;
       > ul{
            list-style: none;
            margin: 0;
            padding:10px 0px;
            background: #CCE5E3;
            min-width: 180px;
            position: sticky;
            top: 60px;
            min-height: calc(100vh - 60px);
           > li{
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin:0px; 
                padding: 0 20px;  
                cursor: pointer;   
                p{
                    color: #1A1818B3;
                    font-size: 14px;    
                    font-family: $primaryfontRegular;
                    &:hover{
                        color: #1A1818;
                    }
                }      
                &:hover{
                    background: #E7F5F4;
                }
                &.react-tabs__tab--selected{
                    background: #E7F5F4;
                    p{
                        color: #1A1818;
                    }
                }
                &:focus-visible {
                    outline: -webkit-focus-ring-color auto 0px;
                }
            }
        }
        .country-list{
            max-height: 100px;
            min-height: auto;
        }
    }
    @media screen and (max-width:767px) {
        .react-tabs {
            display: block;
            width: 100%;
            ul {
                display: flex;
                min-height: auto;
                padding: 0;
                overflow: auto;
                z-index: 1;
                li{
                    p{
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
.tab-panel-content {
    min-width: calc(100% - 180px);
    .profile{
        margin: 20px 20px 0;
        position: relative;
        p{
            color: #453A2D;
            font-size: 18px;
            font-family: $primaryfontMedium;
            padding: 10px 0px;
        }
        .profile-header{
            background: #1B7A70;
            border-radius: 6px 6px 0px 0px;
            opacity: 1;
            display: flex;
            align-items: center;
            padding: 20px;
            .user {
                background: #FFFFFF;
                opacity: 1;
                width: 80px;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                padding: 0;
                color: #1A1818;
                font-size: 18px;
                font-family: $primaryfontMedium;
            }
            p{
                color: #FFFFFF;
                font-size: 18px;
                padding: 0 20px;
                font-family: $primaryfontSemiBold;
                span{
                    display: block;
                    font-size: 14px;
                    font-family: $primaryfontMedium;
                }
            }
        }
        .Profile-body{
            background: #FFFF;
            padding: 40px;
            padding: 40px 120px;
            position: relative; 
            height: auto;
            label{
                color: #1A1818;
                font-size: 12px;
                font-family: $primaryfontRegular;
                padding-bottom: 5px;
                line-height: 15px;
                display: block; 
                z-index: auto;
            }
            .devider {
                background: #1A1818;
                width: 100%;
                height: 1px;
                opacity: 0.1;
                margin: 30px 30px 22px;
            }
            .css-1uvydh2.Mui-disabled {
                opacity: 1;
                -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
            }
            .css-1s2u09g-control{
                align-items: center;
                font-size: 14px;
                color: #1A1818;
                font-family: "InterMedium";
                border: 1px solid rgba(26, 24, 24, 0.34);
                width: 100%;
                height: 40px;
                border-radius: 6px;
                padding: 0 0px;
                cursor: pointer;
            }
            .css-1pahdxg-control{
                border: 1px solid rgba(26, 24, 24, 0.34);
                    box-shadow: none;
                    .css-89lswr-Sa{
                        cursor: pointer;   
                        .css-1gtu0rj-indicatorContainer{
                            cursor: pointer;   
                        }
                    }
            }
            .css-2613gy-menu{position: absolute;top: 0;}
            .react-datepicker-wrapper {
                input{
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #1A1818;
                font-family: "InterRegular";
                border: 1px solid rgba(26, 24, 24, 0.34);
                width: 100%;
                height: 38px;
                max-height: 38px;
                border-radius: 6px;
                padding: 0 15px;
                background: url('../../images/down-chevron.svg') no-repeat;
                background-position: center right;
                background-position-x: 95%;
                cursor: pointer;
                }
            }
            .react-datetime-picker{
                width: 100%;
                .react-datetime-picker__wrapper{
                    align-items: center;
                    font-size: 14px;
                    color: #1A1818;
                    font-family: "InterMedium";
                    border: 1px solid rgba(26, 24, 24, 0.34);
                    width: 100%;
                    height: 40px;
                    border-radius: 6px;
                    padding: 0 15px;
                    .react-datetime-picker__inputGroup{display: flex;align-items: center;}
                    input{
                        color: #1A1818;
                        font-size: 14px;
                        font-family: $primaryfontMedium;
                    }
                    select{
                        display: none;
                    }
                    .react-datetime-picker__inputGroup__divider {
                        &:nth-of-type(2n + 4){
                            transform: rotate(0deg);
                            display: inline-block;
                        }
                        &:nth-of-type(2n + 3){
                            &:after{
                            content: ",";
                            color: black;
                            transform: translateX(-100%);
                            position: absolute;
                            background: #fff;
                            padding: 1px;
                            }
                        }
                        &:nth-last-child(2){
                           display: none;
                        }
                        &:after{
                            content: ":";
                            color: black;
                            transform: translateX(-100%);
                            position: absolute;
                            background: #fff;
                            padding: 1px;
                        }
                    }
                }
            }
            .MuiDropzoneArea-root{
                background: rgb(231 245 244 / 60%);
                border: 1px dashed #1A1818;
                border-radius: 6px;
                width: 100%;
                max-height: 90px;
                min-height: auto;
                box-shadow: none; 
                display: inline-flex;
                align-items: center;
                justify-content: center;
                //margin-top: 5px;
                overflow: initial;
                &.customsdropzone{
                    background: $white;
                    .MuiGrid-spacing-xs-1 {justify-content: center;}
                }
                p{
                    color: #1A1818;
                    font-size: 14px;
                    font-family: $primaryfontMedium;
                }
                .MuiDropzoneArea-icon{
                    display: none;
                }
                .MuiChip-deleteIcon {
                    position: absolute;
                    top: -10px;
                    z-index: 102;
                    right:0;
                    background: #333;
                    color: #fff;
                    opacity: 1;
                    border-radius: 50%;
                    margin: 0;
                }
                .MuiDropzoneArea-text{
                    color: rgb(51, 51, 51, 0.7);
                    font-family: $primaryfontRegular;
                    font-size: 14px;
                }
                .MuiDropzonePreviewList-removeButton{    right: 0;
                    top: -15px;}
                    .MuiDropzonePreviewList-imageContainer{
                        position: initial;
                        .MuiDropzonePreviewList-image {
                            min-height: 70px;
                            height: 70px;
                        }
                        .MuiChip-outlined{position: initial;
                            .MuiChip-label {    width: 130px;}
                            @media screen and (max-width:1200px) {    width: 90px;}
                        }
                    }
            }
            .badge{
                background: #E7F5F4;
                border-radius: 4px;
                height: 40px;
                width: 100%;
                display: inline-flex;
                align-items: center;
                padding: 0 10px;
                color: #1A1818;
                text-transform: capitalize;
                font-size: 14px;
                font-family: $primaryfontRegular;
                img {
                    margin: 0 10px;
                }
            }
            .question_switch{
                display: flex;
                align-items: center;
                margin: 15px 0px 25px;
                position: relative;
            }
            .select-field{
                display: flex;
                align-items: center;
                margin: 15px 0 20px;
                span{
                    color: #1A1818;
                    opacity: 0.7;
                    font-size: 14px;
                    font-family: $primaryfontMedium;
                }
                p{
                    white-space: nowrap;
                }
                .select-option-doc{
                    width: 100px;
                    margin: 15px 10px;
                }
            }
            .switch-field{
                margin: 0;
            }
            .select-option-doc{
                .MuiNativeSelect-select{
                    min-height: 40px;
                    padding: 0 14px;
                    display: flex;
                    align-items: center;
                }
                .css-yjsfm1 {
                    display: none;
                }
                legend{
                    display: none;
                }
                .MuiOutlinedInput-notchedOutline{
                    border: 1px solid rgba(26, 24, 24, 0.34);
                    top: 0px;
                    &:hover{
                        border: 1px solid rgba(26, 24, 24, 0.34);
                    }
                    
                }
                .css-1ualgfl-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline {
                    border: 1px solid rgba(26, 24, 24, 0.34);
                }
            }
            .switch-field{
                display: inline-flex;
                position: relative;
                align-items: center;
                .MuiSwitch-sizeMedium{
                    width: 65px;
                    height: 30px;
                    padding: 0px;
                    .Mui-checked{
                        transform: translateX(35px);
                        padding: 0;
                        .MuiSwitch-thumb {
                            margin: 6px;
                        }
                    }
                    .MuiSwitch-thumb {
                        width: 17px;
                        height: 17px;
                        margin: -3px;
                        background: #FFFFFF;
                        border: 1px solid #1A18181A;
                    }
                    .MuiSwitch-track{
                        background: #F3F5FA;
                        opacity: 1;
                        border: 1px solid #1A18181A;
                        border-radius: 16px;
                        &:before{display: none;}
                        &:after {
                            display: block;
                            color: #1A1818;
                            content: 'No';
                            right: 12px;
                        }
                        &:before, &::after {
                            font-size: 12px;
                            font-family: $primaryfontMedium;
                            content: 'No';
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
                .css-ahj2mt-MuiTypography-root {
                    color: #1A1818;
                    opacity: 0.7;
                    font-size: 14px;
                    font-family: $primaryfontRegular;
                    margin: 0 10px;
                }
                .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
                    opacity: 1;
                    background: #8ABB2A; 
                    position: absolute;
                    &:before{display: block;
                        color: #fff;
                        content: 'Yes';
                        left: 12px;
                    }
                    &:after {display: none;}
                }
            }
            &.profile-tab{
                .MuiDropzonePreviewList-removeButton {
                    opacity: 1;
                }
            }
            &.branding{
                .drop-label{
                    font-size: 12px;
                    color: #1A1818;
                    opacity: 0.7;
                }
                .MuiDropzoneArea-text{
                    color: rgb(51, 51, 51, 0.7);
                    font-family: $primaryfontRegular;
                    font-size: 14px;
                    &:after{
                        content:'Browse';
                        color: #8ABB2A;
                        opacity: 1;
                        transform:translateX(-100%);
                        position:absolute;
                        text-decoration: underline;
                    }
                }
            }
            .text-area {
                border: 1px solid #1A181833;
                border-radius: 6px;
                padding: 20px;
                p{
                    color: #1A1818;
                    opacity: 0.7;
                    font-size: 14px;
                    font-family: $primaryfontRegular;
                }
            }
            @media screen and (max-width:1200px) {padding: 20px 20px;}
            @media screen and (max-width:991px) {padding: 0px 20px;}
            @media screen and (max-width:952px) {padding: 20px 20px;}
            @media screen and (max-width:767px) {padding: 20px 20px;}
        }
    }
}

.Profile-footer{
    background: #8ABB2A;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 0px 120px;
    position: sticky;
    width: 100%;
    bottom: 0;
    .more{
        margin: 0;
    }
    &.table-footer{    padding: 0px 20px;}
    @media screen and (max-width:1200px) {padding: 0px 20px;}
    @media screen and (max-width:991px) {padding: 0px 20px;}
    @media screen and (max-width:952px) {padding: 0px 20px;}
    @media screen and (max-width:767px) {padding: 0px 20px;}
}
