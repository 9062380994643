// $primary_color: #8ABB2A;
// $btn_color: #8ABB2A;
// $white: #ffffff;
// $body_bg_color: #21988C;
// $header_bg_color: #F3F5FA;
// $border: #AEAFB3;
// $black: #000000;
// $heding: #1A1818;
// $pending: #DE6417;
// $pending_light: #E6AC88;
// $checked: #0D988C;
// $side_heading: #453A2D;
// $thead: #FEFDFB;
// $model: #313131;
// $select: #18121D;
// $t_head: #655C52;

/////////////////////////////////////////////////////////
$white : #ffffff;
$black : #000000;
$primary-color : #1A1818;
$secondary-color : #8ABB2A;
$base-color : #F3F5FA;